import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/hoc/with-translate';
import LinkList from '../../../link-list';
import { getShowPostCount, getArchiveLinks } from '../../selectors/archive-selectors';
import { getLinkText } from '../../../common/services/get-link-text';

const Archive = ({ t, archiveLinks, showPostCount }) => (
  <nav aria-label={t('aria-label.archive')}>
    <LinkList
      links={archiveLinks.map((archiveLink) => {
        const monthAndYear = t('archive-link.month-and-year-utc', { time: archiveLink.periodStart });
        const { text, ariaLabel } = getLinkText({
          showPostCount,
          text: monthAndYear,
          postCount: t('archive-link.post-count', { count: archiveLink.postCount }),
          ariaText:
            archiveLink.postCount === 1
              ? t('archive-link.a11y-post-count', { count: archiveLink.postCount })
              : t('archive-link.a11y-post-count-plural', { count: archiveLink.postCount }),
        });

        return {
          key: archiveLink.key,
          path: archiveLink.path,
          text,
          ariaLabel,
        };
      })}
      emptyState={{ text: t('empty-state.no-posts') }}
    />
  </nav>
);

Archive.propTypes = {
  t: PropTypes.func.isRequired,
  archiveLinks: PropTypes.array.isRequired,
  showPostCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  archiveLinks: getArchiveLinks(state),
  showPostCount: getShowPostCount(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(Archive);
